<div
  elTestId="gMpRfHr69knEz0PflWTmm"
  class="sdui-loader"
  [ngClass]="{ enable: enable }"
  *ngIf="type === 'fullpage'"
>
  <div
    elTestId="POTCqHwHNEOxJCQkxAUkH"
    class="a-activity-indicator {{ size }}"
  >
    <div
      elTestId="K97YwZ-68-FF3m1151LNT"
      class="a-activity-indicator__top-box"
    ></div>
    <div
      elTestId="qMaoxvJfeP_gzcxuDkUp9"
      class="a-activity-indicator__bottom-box"
    ></div>
  </div>
</div>

<div
  elTestId="lZJuruuyTNWqLVc68uXzR"
  class="a-activity-indicator {{ size }}"
  [ngClass]="{ enable: enable }"
  *ngIf="type === 'inline'"
>
  <div
    elTestId="C2fQ0PG_Fi7Fma2i6GpA8"
    class="a-activity-indicator__top-box"
  ></div>
  <div
    elTestId="HeNWUyleGyLBX4YZEBmM_"
    class="a-activity-indicator__bottom-box"
  ></div>
</div>
