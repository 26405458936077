import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  standalone: true,
  templateUrl: './rating.component.html',
})
export class RatingComponent {
  @Input() rating: number = 0;
  @Input() numberOfRatings: number = 0;

  readonly stars = [0, 1, 2, 3, 4];
}
