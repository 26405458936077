import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAppThemeType, IIconPlacementType } from '../../shared/models';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() theme: IAppThemeType = 'primary';
  @Input() icon: string = '';
  @Input() iconPlacement: IIconPlacementType = 'left';
  @Input() buttonLabel: string = '';

  @Output() clicked = new EventEmitter<boolean>();
}
