<button
  type="button"
  class="a-button a-button--{{ theme }} {{
    !icon ? '-without-icon' : ''
  }} button-with-icon-{{ iconPlacement }}"
  (click)="clicked.emit()"
>
  @if (iconPlacement === 'left') {
    <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  }
  <span class="a-button__label">{{ buttonLabel }}</span>
  @if (iconPlacement === 'right') {
    <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  }
</button>
<ng-template #iconTemplate>
  <i
    class="a-icon a-button__icon {{ icon }}"
    title="{{ icon }}"
  ></i>
</ng-template>
