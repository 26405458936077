<div
  elTestId="5XIbg9VLJFYNWo-DAL19z"
  class="m-form-field m-form-field--radio"
>
  <div
    elTestId="VFqOl9WgU6k6RKDHc1i1f"
    class="a-radio-button"
  >
    <input
      elTestId="aWbPPsIDyfY0AwYrH6itt"
      type="radio"
      [name]="name"
      [id]="id"
      [value]="value"
      [checked]="checked ? 'checked' : null"
    />
    <label
      elTestId="D7u6DG-rFZZbUWeQOj-Wp"
      [for]="id"
      >{{ label }}</label
    >
  </div>
</div>
