import { Component } from '@angular/core';

@Component({
  selector: 'app-gray-box',
  standalone: true,
  imports: [],
  templateUrl: './gray-box.component.html',
  styleUrls: ['./gray-box.component.scss'],
})
export class GrayBoxComponent {}
