import { Component, Input } from '@angular/core';
import { HeaderComponent } from '../../dashboard/layout/dashboard-header/dashboard-header.component';
import { PublicFooterComponent } from '../../global/layout/public-footer/public-footer.component';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [HeaderComponent, PublicFooterComponent],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  @Input() allowHeaderFunctions!: boolean;
  @Input() message!: string;
}
