<div elTestId="6wmWXtJsmPS6lJGnX9_7G">
  <div
    elTestId="Gjc6izi2gmIrqXLxnMwTc"
    class="banner-container"
  >
    <div
      elTestId="JZASoA-z-a850_e9nB2pO"
      class="banner-container--info"
    >
      Preview
    </div>
    <app-system-banner
      [message]="markdownValue!.value || ''"
      [type]="bannerType || bannerTypes.INFO"
      [showCloseButton]="false"
    >
    </app-system-banner>
  </div>
  <div
    elTestId="-TgMArWmB8i3Gqj43Ycjq"
    class="m-form-field"
  >
    <div
      elTestId="ZAeK3rRIs39NCxmNDbdPq"
      class="a-text-area a-text-area--dynamic-height"
    >
      <textarea
        elTestId="HMjJyS7KzSlIBHEpxJQDP"
        [formControl]="markdownValue"
        (blur)="onTouched()"
        (input)="updateMarkdownValue()"
        id="1"
        placeholder="{{ 'admin.maintenanceMessageHere' | translate }}"
      ></textarea>
      <div
        elTestId="Lp9zoTAo8CYhJtzDQG8-S"
        class="input-hint"
      >
        <a
          elTestId="peEWupdxFI5iyaXFax1OX"
          [href]="'forms.systemBanner.description.en.hint.link' | translate"
          target="_blank"
        >
          {{ 'forms.systemBanner.description.en.hint.text' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
