<div class="a-rating a-rating--large a-rating--link">
  <a>
    <div class="a-rating__star-container">
      @for (star of stars; track $index) {
        <label>
          <input
            type="radio"
            name="rating"
            [value]="star"
          />

          @if (star < rating) {
            @if (star + 0.5 === rating) {
              <i
                class="a-icon ui-ic-nosafe-star-half"
                title="nosafe-star-half"
              ></i>
            } @else {
              <i
                class="a-icon ui-ic-nosafe-star-fill"
                title="nosafe-star-fill"
              ></i>
            }
          } @else {
            <i
              class="a-icon ui-ic-nosafe-star"
              title="nosafe-star"
            ></i>
          }
        </label>
      }
    </div>
    <div class="a-rating__label-container">
      <span class="a-rating__label">{{ rating }}</span>
      <span class="a-rating__label a-rating__label--complete"
        >({{ numberOfRatings }})</span
      >
    </div>
  </a>
</div>
