<mat-form-field appearance="fill">
  <mat-label> {{ label }}</mat-label>
  <mat-select
    [formControl]="control"
    [multiple]="multiSelect"
    id="userInput"
  >
    <mat-checkbox
      class="custom-checkbox"
      *ngIf="selectAllOptionVisible"
      (change)="toggleSelection($event)"
      [checked]="optionList?.length === control.value?.length"
      color="primary"
    >
      {{ 'interface.all' | translate }}</mat-checkbox
    >

    <mat-option
      *ngFor="let option of optionList; trackBy: optionListTrackByFn"
      [value]="option.value"
      [disabled]="option.disabled"
      [matTooltip]="
        (option.showTooltip && option.tooltipTranslationKey) || '' | translate
      "
      [matTooltipDisabled]="!option.showTooltip"
      >{{ option.label }}</mat-option
    >
  </mat-select>
</mat-form-field>
